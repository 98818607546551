<template lang="pug">
  .schedule-container.d-flex.justify-content-between.align-items-center
    AppDropdown.start(
      close-on-select
      :class="{ invalid: isStartInvalid }"
      :value="selectedStartTime"
      value-key="minutes"
      title-key="label"
      :items="startTimes"
      :disabled="disabled"
      :placeholder="''"
      @select="handleChange('start', $event)"
      orderDirection="keep"
    )
    .separator.d-flex.justify-content-center.align-items-center(v-if="!onlyStart") ~
    AppDropdown.end(
      v-if="!onlyStart"
      close-on-select
      :class="{ invalid: isEndInvalid }"
      :value="selectedEndTime"
      value-key="minutes"
      title-key="label"
      :items="endTimes"
      :disabled="disabled"
      :placeholder="''"
      @select="handleChange('end', $event)"
      orderDirection="keep"
    )
</template>

<script>
  // misc
  import { generateTimeRange } from "./helpers"
  import { find, get } from "lodash-es"

  export default {
    props: {
      period: {
        type: Object,
        default: () => new Object()
      },
      disabled: {
        type: Boolean,
        default: false
      },
      onlyStart: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    methods: {
      handleChange(type, { minutes }) {
        this.$emit(`change-${type}`, minutes)
      }
    },

    computed: {
      timeRange() {
        return generateTimeRange()
      },

      start() {
        return get(this.period, "start.value")
      },

      isStartInvalid() {
        return get(this.period, "start.invalid")
      },

      end() {
        return get(this.period, "end.value")
      },

      isEndInvalid() {
        return get(this.period, "end.invalid")
      },

      startTimes({ timeRange, end }) {
        return end ? timeRange.filter(({ minutes }) => minutes < end) : timeRange
      },

      endTimes({ timeRange, start }) {
        return start ? timeRange.filter(({ minutes }) => minutes > start) : timeRange
      },

      selectedStartTime() {
        return find(this.startTimes, ({ minutes }) => this.start === minutes)
      },

      selectedEndTime() {
        return find(this.endTimes, ({ minutes }) => this.end === minutes)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .schedule-container
    width: 224px

    .separator
      font-size: 30px
      font-weight: 100
      line-height: 24px
      margin: 0 4px

    ::v-deep
      .app-select
        padding: 0
        width: 45%
        &.medium
          .dropdown-toggle
            padding: 0 7px
            height: 40px
            border-color: #ccc
            &.disabled
              background-color: #f3f3f3
              svg
                display: none
</style>
