import { i18n } from "@/config"
import { map, range } from "lodash-es"
import { startOfCurrentDateInTimeZone } from "@/helpers/dates"
import { format as dateFormat, setMinutes } from "date-fns"

const MINUTES_IN_DAY = 60 * 24
const SCHEDULE_INTERVAL_IN_MINUTES = 15
const MINUTES_RANGE = range(0, MINUTES_IN_DAY, SCHEDULE_INTERVAL_IN_MINUTES)

const getTimeLabel = currentTime => (currentTime.getHours() >= 12 ? "pm" : "am")

const getFormattedTime = time => {
  const label = getTimeLabel(time)
  const formattedTime = dateFormat(time, "hh:mm")
  return i18n.t(`time.label.${label}`, { value: formattedTime })
}

export const generateTimeRange = () => {
  const startTime = startOfCurrentDateInTimeZone()
  return map(MINUTES_RANGE, minutes => {
    const currentTime = setMinutes(startTime, minutes)
    return { minutes, label: getFormattedTime(currentTime) }
  })
}
